declare global {
    interface Window {
        phrasing: { [v: string]: string },
        customPhrasing: { [v: string]: string }
    }
}

const defaultPhrasing = {
    state: 'Florida',
    stateAbbreviation: 'FL',
    business: 'LLC',
    // businessName: 'GA Business Document Center',
    // state: 'Georgia',
    // business: 'business',
    certificateOf: 'Status',
    // serverUrl: 'http://localhost:3004',
    serverUrl: 'https://fcds-bankai-34d065853ebc.herokuapp.com',
    // serverUrl: 'https://f952-2601-588-8100-980-f1c2-dfab-eb23-a430.ngrok-free.app',
    // serverUrl: 'http://local.chew.com:3004',
    // serverUrl: 'https://fcds-payments.herokuapp.com',
    referenceLength: 12,
    // referenceLength: 7,
    // referenceMask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    // referenceValidation: /^[0-9]{6}-[0-9]{7}$/i,
    // referenceErrorMessage: 'Reference number must be exactly 13 characters long, and in format XXXXXX-XXXXXXX',
    secOfState: 'Sunbiz',

    selectShippingText: 'To begin, select below how you would like to receive your documents:',

    // purchase page 2 settings
    step1Title: 'New Businesses',
    customProductSelectMessage: '',

    // paymentGateway: 'bankful',
}


function generatePhrasing() {
    const phrasing: { [v: string]: any } = {
        ...defaultPhrasing
    }

    if (window.phrasing) {
        Object.keys(window.phrasing).forEach((key) => {
            phrasing[key] = window.phrasing[key]
        })
    }

    if (window.customPhrasing) {
        Object.keys(window.customPhrasing).forEach((key) => {
            phrasing[key] = window.customPhrasing[key]
        })
    }

    return phrasing
}

export const sitePhrasing = generatePhrasing()

export const titleCase = (str: string) => `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`
